import request from '@/utils/request'


// 查询广告-信息列表
export function listAdvertisingMessage(query) {
  return request({
    url: '/platform/advertising-message/list',
    method: 'get',
    params: query
  })
}

// 查询广告-信息分页
export function pageAdvertisingMessage(query) {
  return request({
    url: '/platform/advertising-message/page',
    method: 'get',
    params: query
  })
}

// 查询广告-信息详细
export function getAdvertisingMessage(data) {
  return request({
    url: '/platform/advertising-message/detail',
    method: 'get',
    params: data
  })
}


// 广告审核
export function advertisingApply(data) {
  return request({
    url: '/platform/video/apply',
    method: 'post',
    data: data
  })
}

// 新增广告-信息
export function addAdvertisingMessage(data) {
  return request({
    url: '/platform/advertising-message/add',
    method: 'post',
    data: data
  })
}

// 修改广告-信息
export function updateAdvertisingMessage(data) {
  return request({
    url: '/platform/advertising-message/edit',
    method: 'post',
    data: data
  })
}

// 删除广告-信息
export function delAdvertisingMessage(data) {
  return request({
    url: '/platform/advertising-message/delete',
    method: 'post',
    data: data
  })
}
