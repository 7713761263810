<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="广告位置" prop="advertisingPlace">
                <a-input v-model="queryParam.advertisingPlace" placeholder="请输入广告位置" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="广告形式" prop="advertisingShape">
                <a-input v-model="queryParam.advertisingShape" placeholder="请输入广告形式" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >

      <span slot="pointUrl" slot-scope="text,record" v-if="record.pointUrl">
         <a :href="record.pointUrl" target="_blank">跳转链接</a>
      </span>

        <span slot="video" slot-scope="text,record" v-if="record.video">
              <div class="imgbox margin-center" @click="showDetail(record.video)">
                <div class="videomask">
                  <a-icon class="icon" type="play-circle" />
                </div>
                <img  style="width: 48px;height: 48px;"  :src="record.video + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt=""
                      class="response">
              </div>
        </span>

        <img style="width: 48px;height: 48px;" preview="封面" :src="record.cover" v-if="record.cover != null && record.cover != ''" slot="cover" slot-scope="text, record">

        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="record.applicationStatus == 0">
            审核
          </a>
        </span>
      </a-table>

      <!-- 視頻预览播放 -->
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <!-- <img alt="example" style="width: 100%" :src="previewImage" /> -->
        <video style="width: 100%" :src="previewImage" ref="myVideo"
               :poster=" previewImage + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>

      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageAdvertisingMessage,listAdvertisingMessage, delAdvertisingMessage } from '@/api/platform/advertisingMessage'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'AdvertisingMessage',
  components: {
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      previewVisible: false,
      previewImage: '',
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        advertisingPlace: null,
        advertisingShape: null,
        userId: null,
        pointUrl: null,
        competitionId: null,
        areas: null,
        minAge: null,
        maxAge: null,
        sex: null,
        interestTags: null,
        exposureNum: null,
        startTime: null,
        endTime: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '广告位置',
          dataIndex: 'advertisingPlace',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "开屏广告"
            }
            if (value == 2) {
              return "首頁"
            }
          }
        },
        {
          title: '广告形式',
          dataIndex: 'advertisingShape',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 1) {
              return "仅图片視頻"
            }
            if (value == 2) {
              return "加链接"
            }
            if (value == 3) {
              return "加落地页"
            }
            if (value == 4) {
              return "关联赛事"
            }
            if (value == 5) {
              return "加下载按钮"
            }

          }
        },
        {
          title: '广告封面',
          dataIndex: 'cover',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "cover"}
        },
        {
          title: '視頻',
          dataIndex: 'video',
          ellipsis: true,
          align: 'center',
          width: 60,
          scopedSlots: {customRender: "video"}
        },
        {
          title: '用戶昵稱',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '外链地址',
          dataIndex: 'pointUrl',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: "pointUrl"}
        },
        {
          title: '赛事標題',
          dataIndex: 'competitionTitle',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '总曝光量',
          dataIndex: 'exposureNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "正常"
            }
            if (value == 1) {
              return "下架"
            }
          }
        },
        {
          title: '審核狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "审核中"
            }
            if (value == 1) {
              return "已同意"
            }
            if (value == 2) {
              return "已拒绝"
            }
          }
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢广告-信息列表 */
    getList () {
      this.loading = true
     pageAdvertisingMessage(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    showDetail(video){
      this.previewImage = video;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        advertisingPlace: undefined,
        advertisingShape: undefined,
        userId: undefined,
        pointUrl: undefined,
        competitionId: undefined,
        areas: undefined,
        minAge: undefined,
        maxAge: undefined,
        sex: undefined,
        interestTags: undefined,
        exposureNum: undefined,
        startTime: undefined,
        endTime: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delAdvertisingMessage(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/advertising-message/export', {
            ...that.queryParam
          }, `广告-信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less">

.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 50px;
  height: 50px;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  line-height: 55px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 18px !important;
    color: #fff !important;
  }
}
</style>